<template>
  <div class="inner __join-wrap">
    <div class="tit req-type">
      <h1>기업회원 이용약관</h1>
      <p class="stit">호시행일자: 2021년 8월 1일</p>
    </div>
    <div class="sub-cont">
      <div class="terms_wrap" v-html="enterpriseTerms"></div>
    </div>
  </div>
</template>

<script>
import { enterprise } from 'Configs/terms'

export default {
  data() {
    return {
      enterpriseTerms: Object.freeze(enterprise),
    }
  },
}
</script>

<style></style>
