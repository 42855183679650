<template>
  <main class="sub __agree-wrap">
    <personal v-if="viewName === 'personal'"></personal>
    <enterprise v-else></enterprise>
  </main>
</template>

<script>
import Personal from 'Components/terms/Personal'
import Enterprise from 'Components/terms/Enterprise'

export default {
  components: {
    Personal,
    Enterprise,
  },
  data() {
    return {
      viewName: '',
    }
  },
  watch: {
    '$route.params.viewName': {
      immediate: true,
      handler(newVal) {
        this.viewName = newVal
      },
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.params.backRoute) {
        document.getElementById('scroll').scrollIntoView(true)
      }
    })
  },
}
</script>

<style></style>
